<template>
  <div id="main-container" :key="componentKey">

    <!-- Content Start -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="row no-gutters">
        <div v-if="urdudata == false" class="col-lg-12">
          <div class="p-3">
            <div class="w-100">
              <div class="custom-column-banner-heading">
                <div class="custom-heading-without-background border-bottom mb-4">
                  <h2 class="text-capitalize">{{ info.title }}</h2>
                </div>
                <div class="pt-2"></div>
                <div v-if="info.image != '' && info.image != null" class="w-100">
                  <img :src="baseURL+info.image" class="w-100 custom-post-detail" />
                </div>
                <div v-else class="w-100">
                  <b-alert show>Loading...</b-alert>
                </div>
              </div>
            </div>
            <div class="pt-5"></div>
            <div class="text-lg-justify" v-html="info.longDescription"></div>
            <div class="custom-bg">
              <div class="pt-1 pb-2">
                <div class="mt-2 mb-3">
                  <div class="custom-widget mb-4 border-bottom">
                    <h2 class="widget-title text-center">Share This Post</h2>
                  </div>
                  <div class="custom-row justify-content-center">
                    <ShareNetwork network="facebook" :url="'https://ventureher.com/#/detail/'+info.id" :title="info.title">
                      <div style="color:#ea216c; font-size: large;">
                        <a href="https://www.facebook.com/Venterher.Offical/" target="_blank">
                          <i class="fab fa-facebook-square fa-md fa-fw"></i>
                        </a>
                        <a href="https://twitter.com/ventur_h" target="_blank">
                          <i class="fab fa-twitter fa-md fa-fw"></i>
                        </a>
                        <a href="https://www.facebook.com/Venterher.Offical/" target="_blank">
                          <i class="fab fa-facebook-square fa-md fa-fw"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/ventureheroffical/" target="_blank">
                          <i class="fab fa-linkedin fa-md fa-fw"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UCetNHbRcnPDyPdPlKwhGPjw" target="_blank">
                          <i class="fab fa-youtube fa-md fa-fw"></i>
                        </a>
                        <a href="https://www.instagram.com/Ventureher1.offical/" target="_blank">
                          <i class="fab fa-instagram fa-md fa-fw"></i>
                        </a>
                      </div>
                    </ShareNetwork>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="col-lg-12">
          <div class="p-3">
            <div class="w-100">
              <div class="custom-column-banner-heading">
                <div class="custom-heading-without-background border-bottom mb-4">
                  <h2 class="text-capitalize">{{ info.urduTitle }}</h2>
                </div>
                <div class="pt-2"></div>
                <div v-if="info.image != '' && info.image != null" class="w-100">
                  <img :src="baseURL+info.image" class="w-100 custom-post-detail" />
                </div>
                <div v-else class="w-100">
                  <b-alert show>Loading...</b-alert>
                </div>
              </div>
            </div>
            <div class="pt-5"></div>
            <div style="float: right;" class="text-lg-justify" v-html="info.urduLongDescription"></div>
            <div class="custom-bg">
              <div class="pt-1 pb-2">
                <div class="mt-2 mb-3">
                  <div class="custom-widget mb-4 border-bottom">
                    <h2 class="widget-title text-center">Share This Post</h2>
                  </div>
                  <div class="custom-row justify-content-center">
                    <ShareNetwork network="facebook" :url="'https://ventureher.com/#/detail/'+info.id" :title="info.title">
                      <i class="fab fa-facebook-f"></i>
                    </ShareNetwork>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Spacer -->
      <div class="pt-5"></div>
      <!-- Navigate To WhyVentureHer -->
      <div class="text-center mb-5">
        <div class="custom-row justify-content-center">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
            <b-icon icon="trophy"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
      </div>
      <div>
        <div class="text-center clear-bottom">
          <div class="row no-gutters">
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
              <div v-if="info.type == 3" class="row no-gutters">
                <div class="col-lg-6 mt-1 mb-1">
                  <router-link :to="{ name: 'Home', hash : '#ourWhyVentureHer_id' }" class="btn link-theme text-decoration-none">Why Venture(H)er?</router-link>
                </div>
                <div class="col-lg-6 mt-1 mb-1">
                  <router-link :to="{ name: 'ContactUs' }" class="btn link-theme text-decoration-none">Contact Us</router-link>
                </div>
              </div>
              <div v-else class="mt-1 mb-1">
                <router-link :to="{ name: 'ContactUs' }" class="btn link-theme text-decoration-none">Contact Us</router-link>
              </div>
            </div>
            <div class="col-lg-4"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Spacer -->
    <div class="pt-5"></div>
  </div>
</template>


<script>

import axios from 'axios'
import VueSocialSharing from 'vue-social-sharing'

export default {
  name: 'WhyVentureHerDetail',
  components: {
    VueSocialSharing
  },
  data () {
    return {

      baseURL: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,
      id: this.$route.params.id,
      list: [],
      PageOfItems: [],
      info: [],
      domainUrl: 'https://ventureher.com',
      option: 0,
      urdudata:false,
      // Video Source
      videoUrl: '',
      // posts

      postList: [],

      loading: true,
      errored: false,

      componentKey: 0
    }
  },
  // carousel_latest_jobs
  methods: {
    onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
    getVentureHer () {
      console.log('id : ', this.$route.params.id)
      this.info = []
      axios.get('/api/Shehri/VenturePartnerDetail?id=' + this.$route.params.id)
        .then(res => {
          this.info = res.data.data

          console.log('VentureHer Detail : ', this.info)
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    // Rerender Component
    rerender () {
      this.componentKey += 1
    }
  },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        debugger
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },
  mounted () {
    if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
    this.getVentureHer();
  }
}
</script>
